const ERROR_IS_REQUIRED = 'この項目は必須です。'
const ERROR_EMAIL_INVALID = '無効なメールアドレスです。'
const ERROR_EMAIL_ATS_REQUIRED = 'ATSメールアドレスしか登録できません。もう1度ご確認ください。'
const ERROR_PASSWORD_INVALID = '無効なパスワードです。'
const ERROR_PASSWORD_MIN_6 = 'パスワードは最小6文字が使用可能です。もう1度ご確認ください。'
const ERROR_PASSWORD_CONFIRM = 'パスワードと再入力したパスワードが異なっています。もう1度ご確認ください。'
const ERROR_PROJECT_NAME_INVALID = '無効なプロジェクト名です。もう1度ご確認ください。'
const ERROR_OA_NUMBER_INVALID = '無効なOA番号です。'

const isRequired = (value) => value === '' ? false : true

const isEmailValid = (email) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/  
  return regex.test(email)
}

const isATSEmail = (email) => {
  return email.includes('alphatec-sol.co.jp')
}

const isOANumberValid = (OANumber) => {
  const regex = /^[0-9]{7}$/
  return regex.test(OANumber)
}

const isPasswordLength6 = (password) => {
  return password.length >= 6
}

const isSpecialCharacters = (characters) => {
  const regex = /[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/
  return regex.test(characters)
}

const showError = (input, message) => {
  const formField = input.parentElement

  input.classList.add('is-invalid')

  const feedback = formField.querySelector('.invalid-feedback')
  if (feedback) feedback.innerText = message
}

const hideError = (input) => {
  const formField = input.parentElement
    
  input.classList.remove('is-invalid')
  
  const feedback = formField.querySelector('.invalid-feedback')
  if (feedback) feedback.innerText = ''
}

const checkEmail = (emailElement) => {
  let valid = false
  const email = emailElement.value.trim()

  if (!isRequired(email)) {
    showError(emailElement, ERROR_IS_REQUIRED)
  } else if (!isEmailValid(email)) {
    showError(emailElement, ERROR_EMAIL_INVALID)
  } else if (!isATSEmail(email)) {
    showError(emailElement, ERROR_EMAIL_ATS_REQUIRED)
  } else {
    hideError(emailElement)
    valid = true
  }

  return valid
}

const checkOANumber = (OANumberElement) => {
  let valid = false
  const OANumber = OANumberElement.value.trim()

  if (!isRequired(OANumber)) {
    showError(OANumberElement, ERROR_IS_REQUIRED)
  } else if (!isOANumberValid(OANumber)) {
    showError(OANumberElement, ERROR_OA_NUMBER_INVALID)
  } else {
    hideError(OANumberElement)
    valid = true
  }

  return valid
}

const checkPassword = (passwordElement) => {
  const password = passwordElement.value.trim()
  let valid = false

  if (!isRequired(password)) {
    showError(passwordElement, ERROR_IS_REQUIRED)
  } else if (!isPasswordLength6(password)) {
    showError(passwordElement, ERROR_PASSWORD_MIN_6)
  } else {
    hideError(passwordElement)
    valid = true
  }

  return valid
}

const checkPasswordConfirm = (passwordElement, passwordConfirmElement) => {
  const password = passwordElement.value.trim()
  const passwordConfirm = passwordConfirmElement.value.trim()
  let valid = false

  if (!isRequired(passwordConfirm)) {
    showError(passwordConfirmElement, ERROR_IS_REQUIRED)
  } else if (password !== passwordConfirm) {
    showError(passwordConfirmElement, ERROR_PASSWORD_CONFIRM)
  } else if (isSpecialCharacters(passwordConfirm)) {
    showError(passwordConfirmElement, ERROR_PASSWORD_INVALID)
  }  else {
    hideError(passwordConfirmElement)
    valid = true
  }

  return valid
}

const checkSignUpCode = (signUpCodeElement) => {
  const signUpCode = signUpCodeElement.value.trim()
  let valid = false

  if (!isRequired(signUpCode)) {
    showError(signUpCodeElement, ERROR_IS_REQUIRED)
  } else {
    hideError(signUpCodeElement)
    valid = true
  }

  return valid
}

const checkRefreshToken = (refreshTokenElement) => {
  let valid = false
  const refreshToken = refreshTokenElement.value.trim()

  if (!isRequired(refreshToken)) {
    showError(refreshTokenElement, ERROR_IS_REQUIRED)
  } else {
    hideError(refreshTokenElement)
    valid = true
  }

  return valid
}